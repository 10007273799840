/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export const awsmobile = {
    Auth: {
      Cognito: {
        //  Amazon Cognito User Pool ID
        userPoolId: 'eu-north-1_IxfwtKPDe',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: '5jss51thvhui3mkr7145m3qtki'
      },
    },
};

