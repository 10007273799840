import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context to hold the cache state and functions
const CacheContext = createContext();

// Custom hook to access the cache state and functions
export const useCache = () => useContext(CacheContext);

// Cache provider component to wrap your application
export const CacheProvider = ({ children }) => {
    const [cache, setCache] = useState({});
    const [cacheExpiry, setCacheExpiry] = useState({});

    // Function to invalidate all caches
    const invalidateAllCache = () => {
        setCache({});
        setCacheExpiry({});
    };

    // Function to invalidate the cache for a specific key
    const invalidateCache = (key) => {
        setCache(prevCache => {
            const updatedCache = { ...prevCache };
            delete updatedCache[key]; // Remove the entry for the specified key
            return updatedCache;
        });
    };

    // Function to update the cache for a specific key
    const updateCache = (key, data, ttl = null) => {
        setCache(prevCache => ({
            ...prevCache,
            [key]: data // Update or add the entry for the specified key
        }));

        // Set cache expiry time if TTL is provided
        if (ttl !== null) {
            setCacheExpiry(prevCacheExpiry => ({
                ...prevCacheExpiry,
                [key]: Date.now() + ttl
            }));
        }
    };

    // Function to get data from the cache for a specific key
    const getCachedData = (key) => {
        // Check if cache entry exists and it hasn't expired
        if (cache.hasOwnProperty(key) && (!cacheExpiry.hasOwnProperty(key) || cacheExpiry[key] > Date.now())) {
            return cache[key];
        } else {
            // Invalidate cache entry if it has expired
            invalidateCache(key);
            return null;
        }
    };

    // UseEffect to check cache expiry and invalidate entries
    useEffect(() => {
        const intervalId = setInterval(() => {
            for (const key in cacheExpiry) {
                if (cacheExpiry.hasOwnProperty(key) && cacheExpiry[key] < Date.now()) {
                    invalidateCache(key);
                }
            }
        }, 1000); // Check cache expiry every second

        // Clear interval on unmount
        return () => clearInterval(intervalId);
    }, [cacheExpiry]);

    return (
        <CacheContext.Provider value={{ invalidateCache, updateCache, getCachedData, invalidateAllCache }}>
            {children}
        </CacheContext.Provider>
    );
};
