import React, { Suspense, lazy } from "react";
import { Route, Routes } from 'react-router-dom';

import { awsmobile } from './aws-exports';
import { Amplify } from 'aws-amplify';
import { AccountProvider } from './contexts/AccountContext';
import LoadingSpinner from "./components/LoadingSpinner";
import 'react-toastify/dist/ReactToastify.css';

const EmailConfirmation = lazy(() => import('./pages/Email-template/email-confirmation'));
const ListOfWebsites = lazy(() => import('./pages/Websites/ListOfWebsites'));
const AddWebsite = lazy(() => import('./pages/Websites/AddWebsite'));
const IndexModernSaas = lazy(() => import('./pages/pages/Index/indexModernSaas'));
const PagePricing = lazy(() => import('./pages/pages/company/pagePricing'));
const PageServices = lazy(() => import('./pages/pages/company/pageServices'));
const ContactOne = lazy(() => import('./pages/pages/contact/contactOne'));
const PageAboutus = lazy(() => import('./pages/pages/company/pageAboutus'));
const AuthSignupin = lazy(() => import('./pages/pages/auth/AuthSignupin'));
const AddPanel = lazy(() => import('./pages/Panels/AddPanel'));
const AddSMTPServer = lazy(() => import('./pages/SMTPServers/AddSMTPServer'));
const UserPanelsList = lazy(() => import('./pages/Panels/UserPanelsList'));
const SmtpServersList = lazy(() => import('./pages/SMTPServers/SmtpServersList'));
const AutomationsList = lazy(() => import('./pages/Automations/AutomationsList'));
const AddAutomation = lazy(() => import('./pages/Automations/AddAutomation'));
const PageTerms = lazy(() => import('./pages/pageTerms'));
const UiComponents = lazy(() => import('./pages/ui-components'));
const PageError = lazy(() => import('./pages/Miscellaneous/error'));
const ProtectedRoute = lazy(() => import('./pages/utility/ProtectedRoutes'));
const Subscription = lazy(() => import('./pages/SubscriptionManagement'));


Amplify.configure(awsmobile);

function App() {
  return (
    <AccountProvider>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route exact path="/" element={<IndexModernSaas />} />
          <Route path="/index" element={<IndexModernSaas />} />
          <Route path="/page-pricing" element={<PagePricing />} />
          <Route exact path="/page-services" element={<PageServices />} />
          <Route exact path="/contact-one" element={<ContactOne />} />
          <Route exact path="/page-aboutus" element={<PageAboutus />} />
          <Route exact path="/auth-signupin" element={<AuthSignupin />} />
          <Route exact path="*" element={<PageError />} />
          <Route exact path="/page-terms" element={<PageTerms />} />
          <Route path="/smtp-confirmation" element={<EmailConfirmation />} />

          <Route element={<ProtectedRoute routeToCatchNonAuth="/" />}>
            {/* <Route exact path="/dashboard" element={<Index/>}/> */}
            {/* <Route exact path="/dashboard" element={<Index/>}/> */}
            <Route path="/ui-components" element={<UiComponents />} />
            <Route path="/websites-list" element={<ListOfWebsites />} />
            <Route path="/add-website" element={<AddWebsite />} />
            <Route path="/add-panel" element={<AddPanel />} />
            <Route path="/panels-list" element={<UserPanelsList />} />
            <Route path="/add-smtp" element={<AddSMTPServer />} />
            <Route path="/smtp-list" element={<SmtpServersList />} />
            <Route path="/automations-list" element={<AutomationsList />} />
            <Route path="/add-automation" element={<AddAutomation />} />
            <Route path="/subscription" element={<Subscription />} />

            {/* <Route path="/profile" element={<Profile/>}/>
            <Route path="/profile-notification" element={<ProfileNotification/>}/> */}
            {/* <Route path="/pricing" element={<Pricing/>}/> */}

          </Route>
        </Routes>
      </Suspense>
    </AccountProvider>
  );
}

export default App;
