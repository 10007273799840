import { createContext, useContext, useState } from 'react';

const AccountContext = createContext(null);

const AccountProvider = ({ children }) => {

  const [user, setUser] = useState(null);

  const storeUserData = (userData) => {
    setUser(userData);
  };

  return (
    <AccountContext.Provider value={{ user, storeUserData }}>
      {children}
    </AccountContext.Provider>
  );
};

const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};

export { AccountProvider, useAccount, AccountContext };
