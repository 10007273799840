// LoadingSpinner.js
import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoadingSpinner = () => {
  return (
    <div style={spinnerStyle}>
      <ClipLoader size={100} color={"#4f46e5"} loading={true}   speedMultiplier={0.5}/>
    </div>
  );
};

const spinnerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
};

export default LoadingSpinner;
